









































import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { getModule } from 'vuex-module-decorators';
import { WithPrefetch } from '@/core/vue.types';
import config from '@/env';
import { PopularItem, PopularService } from '@/modules/guide/popular';
import CarouselItem from '@/themes/v1/components/Carousel/CarouselItem.vue';
import Carousel from '@/themes/v1/components/Carousel/Carousel.vue';
import BgImage from '@/themes/v1/components/BgImage.vue';

@Component({
  components: { BgImage, Carousel, CarouselItem },
})
export default class PopularCarousel extends Vue implements WithPrefetch {
  @Prop({ type: String, default: '', required: true })
  segment!: string;

  @Inject() popularService!: PopularService;
  error = false;
  loading = false;

  items: PopularItem[] = [];

  guideStore = getModule(GuideStore, this.$store);
  slideBreakpoints = {
    1170: {
      perView: 1,
    },
    3000: {
      perView: 1.2,
      dragThreshold: false,
    },
  };

  async prefetch(): Promise<void> {
    await this.loadPopular();
  }

  async loadPopular() {
    try {
      this.loading = true;
      const language = this.$i18n.locale;
      const storeData = this.guideStore.popular[this.segment];
      if (storeData == null || this.guideStore.resourceLanguage != language) {
        const items = await this.popularService.get(
          config.game,
          language,
          this.segment
        );
        this.items = items;
        if (this.guideStore.resourceLanguage != language) {
          this.guideStore.resetResourceLanguage(language);
        }
        this.guideStore.setPopular({
          segment: this.segment,
          items: this.items,
        });
      } else {
        this.items = storeData;
      }
      this.error = false;
    } catch {
      this.error = true;
    } finally {
      this.loading = false;
    }
  }
}
