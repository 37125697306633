






















































import { Component, Vue } from 'vue-property-decorator';
import { GuideStore } from '@/themes/v1/stores/guide.store';
import { getModule } from 'vuex-module-decorators';
import { SsrCtx, WithAsyncData, WithPrefetch } from '@/core/vue.types';

import config from '@/env';
import Breadcrumbs from '@/themes/v1/components/Breadcrumbs.vue';
import PopularCarousel from '@/themes/v1/views/guide/section/guide/PopularCarousel.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import AskBox from '@/themes/v1/components/AskBox.vue';
import Sticky from '@/themes/v1/components/Sticky.vue';
import { ArticleService } from '@/modules/guide/articles/article.service';
import TwoColumn from '@/themes/v1/components/TwoColumn.vue';
import { AppStore } from '@/themes/v1/stores/app.store';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { universalRedirect } from '@/themes/v1/fns/router';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import QuestionMark from '@/themes/v1/components/QuestionMark.vue';

@Component({
  components: {
    TwoColumn,
    Sticky,
    AskBox,
    Box,
    BoxContent,
    PopularCarousel,
    Breadcrumbs,
    NeedSomethingElse,
    QuestionMark,
  },
})
export default class GuideSection
  extends Vue
  implements WithPrefetch, WithAsyncData {
  guideStore = getModule(GuideStore, this.$store);
  appStore = getModule(AppStore, this.$store);

  error = false;

  async asyncData(ctx: SsrCtx) {
    const articleService = resolveFromCtx<ArticleService>(
      'articleService',
      ctx
    );
    const groups = await articleService.getGuideViewItems(
      config.game,
      ctx.app.$i18n.locale,
      'guide'
    );

    if (!groups.length) {
      universalRedirect(ctx, '');
      return;
    }

    const guideStore = getModule(GuideStore, ctx.store);
    guideStore.setItems({
      items: groups,
      segment: 'guide',
    });
  }

  async prefetch(): Promise<void> {
    this.guideStore.setSection(this.section);
  }

  get groups() {
    return this.guideStore.sectionItems;
  }

  get section() {
    return this.appStore.sections['guide'];
  }
}
